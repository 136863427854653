.wrapper .container.cyr h2{
    font-size: 4.875vw;
}
.wrapper .container h2{
    font-family: 'Saira Extra Condensed', 'Open Sans Condensed', sans-serif;
    font-size: 5vw;
    font-weight: normal;
    width: 67vw;
    padding: 0 15vw 0 0;
    box-sizing: border-box;
    text-align: center;
    line-height: 125%;
    margin: 0 auto;
    margin-top: 10vw;
}
.wrapper .container h2 a{
    color: #157ac1;
}

.wrapper .container .share{
    position: absolute;
    top: 38vw;
    right: 2vw;
    z-index: 1;
}
.wrapper .container .start{
}

div.share.lan-hr div.fb-like.fb_iframe_widget{width:100px; }
div.share.lan-hr div.fb-like.fb_iframe_widget span{width:100px !important; }
div.share.lan-hr div.fb-like.fb_iframe_widget span iframe{width:100px !important; }

div.share.lan-rs div.fb-like.fb_iframe_widget{width:107px; }
div.share.lan-rs div.fb-like.fb_iframe_widget span{width:107px !important; }
div.share.lan-rs div.fb-like.fb_iframe_widget span iframe{width:107px !important; }

div.share.lan-me div.fb-like.fb_iframe_widget{width:107px; }
div.share.lan-me div.fb-like.fb_iframe_widget span{width:107px !important; }
div.share.lan-me div.fb-like.fb_iframe_widget span iframe{width:107px !important; }

div.share.lan-ba div.fb-like.fb_iframe_widget{width:100px; }
div.share.lan-ba div.fb-like.fb_iframe_widget span{width:100px !important; }
div.share.lan-ba div.fb-like.fb_iframe_widget span iframe{width:100px !important; }

div.share.lan-de div.fb-like.fb_iframe_widget{width:95px; }
div.share.lan-de div.fb-like.fb_iframe_widget span{width:95px !important; }
div.share.lan-de div.fb-like.fb_iframe_widget span iframe{width:95px !important; }

/*
.wrapper .container .start .button{
    width: 64vw;
    display: block;
    text-align: center;
    font-size: 3.5vw;
    padding: 0;
    text-decoration: none;
    color: #157ac1;
    overflow: hidden;
    position: relative;
    background-image: url(/assets/images/logo.png);
    background-size: 23vw 23vw;
    background-position: 0 0;
    background-repeat: no-repeat;
}
.wrapper .container .start .button:after{
    content: "START";
    color: #ffffff;
    background-color: #157ac1;
    border: 0;
    font-size: 8vw;
    padding: 2.25vw 1vw 0 1vw;
    margin: 10.675vw 0 0 22.75vw;
    width: 41vw;
    border-radius: 0 4vw 4vw 0;
    display: block;
    text-align: center;
    text-decoration: none;
    float: left;
    line-height: 100%;
    font-family: 'Quicksand', sans-serif;
    height: 12.325vw;
    box-sizing: border-box;
}
.wrapper .container .start .button h3{
    float: left;
    display: block;
    margin: 0;
    text-align: center;
    width: 39vw;
    line-height: 100%;
    font-family: 'Saira Extra Condensed', sans-serif;
    font-size: 5.25vw;
    font-weight: normal;
    color: #009688;
    padding: 4.75vw 2vw 13.125vw 23vw;
    position: absolute;
    top: 0;
    right: 0;
}
.wrapper .container .start .button.multiplication h3{
    background-image: url(/assets/images/multiplication-single.png);
    background-size: 11.875vw 12vw;
    background-position: 0 11.125vw;
    background-repeat: no-repeat;
}
*/
.wrapper .container .features{
    list-style-type: none;
    width: 70vw;
    float: right;
    overflow: hidden;
    margin: 18vw 0 0 0;
}
.wrapper .container .features li{
    padding: 0.5vw 0 0 0;
    float: right;
    clear: both;
}
.wrapper .container .features li a{
    font-size: 4vw;
    background-color: rgba(225, 226, 227, 0.43);
    padding: 2vw 4vw 1.5vw 4vw;
    color: #009688;
    float: right;
    display: block;
    border-radius: 1.5vw 0 0 1.5vw;
}
.wrapper .container .features li a.all{
    background-color: #009688;
    color: #ffffff;
}



header .latin-cyrilic{
    position: absolute;
    right: 0.125em;
    top: 25.5vw;
    padding: 0;
    margin: 0;
    list-style-type: none;
}
header .latin-cyrilic li{
    float: left;
    padding: 0 0.325vw 0 0.325vw;
}
header .latin-cyrilic li a{
    margin: 0;
    font-size: 3vw;
    background-color: #e1e1e1;
    color: #ffffff;
    padding: 0.5vw 2vw 0.75vw 2vw;
    line-height: 100%;
}
header .latin-cyrilic li a.active{
    background-color: #e8eff9;
    color: #157ac1;
}

header .flags img{
    width: 3.5em;
    position: absolute;
    right: 3vw;
    top: 27vw;
    padding: 0.25em;
    background-color: #fbfcfe;
    z-index: 95;
}
header ul.lang{
    display: none;
    position: absolute;
    width: 100%;
    background-color: #eaeaea;
    margin: 0;
    padding: 1vw 4vw 0 4vw;
    box-sizing: border-box;
    z-index: 90;
    border-style: solid;
    border-width: 0 0 3vw 0;
    border-color: #157ac1;
}
header ul.lang li.first{
    border: 0;
}
header ul.lang li{
    display: block;
    margin: 0;
    border-style: solid;
    border-width: 0.25vw 0 0 0;
    border-color: white;
}
header ul.lang li.non-link{
    padding: 2vw 0 2vw 0;
    border: 0;
    color: #999999;
}
header ul.lang li.separator{
    display: block;
    border-style: solid;
    border-width: 0.25vw 0 0 0;
    border-color: #05988a;
    margin: 0;
    padding: 1.25vw 0 1.5vw 0;
    line-height: 145%;
    font-size: 5vw;
}
header ul.lang li.separator.first{
    margin: 2vw 0 0 0;
}
header ul.lang li.separator a{
}
header ul.lang li a{
    display: block;
    font-size: 4.25vw;
    background-repeat: no-repeat;
    background-size: 1.75em;
    padding: 0 0 0 2.125em;
    background-position: 0 0.25em;
}
header ul.lang li a.hr{
    background-image: url(/assets/images/flag-hr.png);
}
header ul.lang li a.en{
    background-image: url(/assets/images/flag-en.png);
}
header ul.lang li a.rs{
    background-image: url(/assets/images/flag-rs.png);
}
header ul.lang li a.me{
    background-image: url(/assets/images/flag-me.png);
}





@media only screen and (min-width:30em){
    .wrapper .container.cyr h2{
        font-size: 1.35em;
        margin-top: 2.5em;
    }
    .wrapper .container h2{
        font-size: 1.5em;
        width: 10em;
        margin-top: 2em;
        padding: 0;
    }
    html[lang="de"] .wrapper .container h2{
        font-size: 1.4em;
    }
    
    .wrapper .container .share{
        top: 11em;
        right: 0.5em;
    }
    
    .wrapper .container .features{
        width: 100%;
        max-width: 40em;
        margin: 0 auto;
        margin-top: 5em;
        display: block;
        float: none;
        padding: 0;
    }
    .wrapper .container .features li{
        padding: 0.25em 0 0 0;
    }
    .wrapper .container .features li a{
        font-size: 1em;
        padding: 0.75em 1em 0.75em 2em;
        border-radius: 0.5em 0 0 0.5em;
    }
    
    
    header .latin-cyrilic{
        right: 0.125em;
        top: 8.5em;
    }
    header .latin-cyrilic li{
        padding: 0 0.125em 0 0.125em;
    }
    header .latin-cyrilic li a{
        font-size: 0.8em;
        padding: 0.1875em 0.5em 0.25em 0.5em;
    }
    
    
    header .flags img{
        width: 5em;
        right: 1em;
        top: 9em;
    }
    
    
    
    header ul.lang{
        max-width: 40em;
        margin: 0 auto;
        font-size: 1em;
        padding: 0.25em 1em 0.25em 1em;
        border-width: 0 0 0.5em 0;
        left: 0;
        right: 0;
    }
    header ul.lang li{
        border-width: 0.25em 0 0 0;
    }
    header ul.lang li.non-link{
        color: #999999;
        padding: 0.5em 0 0.25em 0;
    }
    header ul.lang li.separator{
        border-width: 0.0625em 0 0 0;
        margin: 0.25em 0 0 0;
        padding: 0.325em 0 0.325em 0;
        font-size: 1em;
    }
    header ul.lang li.separator.first{
        margin: 2em 0 0 0;
    }
    header ul.lang li a{
        font-size: 1em;
    }
}
